import React, { useEffect, useState } from "react";
import DashHome from "./DashHome";
import Users from "../userManagement/Accounts";
import SendMail from "../mailerSystem/sendMail";
import Mailer from "../mailerSystem/sendBuldMail";
import CreateService from "../Services/CreateServices";
import Services from "../Services/Services";
import Kennal from "../Kennal/kennal";
import PetEnquiry from "../PetEnquiry/PetEnquiry";
import ServiceProvider from "../ServciceProvider/allDetails"
import PAM from '../packerMovers/allDetails';
import PetEnquiries from '../PetEnquiries/Index';
import CreateUser from '../userManagement/Users'
import { TbDoorExit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";

import companyInfo from '../../companyInfo.json';
import AllOrders from "../orders/allOrders";



import { TbNotes, TbVersionsFilled } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Dashboard = () => {
  const valueList = localStorage.getItem("listvalue");
  const [settingName, setSettingName] = useState(valueList);
  const [listValue, setListValue] = useState();
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(501);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (totalCount === 500 || totalCount === 1000 || totalCount === 5000) {
      setShowConfetti(true);

      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [totalCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalCount((prevCount) => prevCount + 1);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const listClick = (e) => {
    const value = e.target.value;
    localStorage.setItem("listvalue", value);
    setSettingName(value);
    setListValue(value);
  };

  const userToken = localStorage.getItem("userToken");
  const data = [
    {
      icon: <RxDashboard />,
      name: "Dashboard",
      path: "/dashboard/home",
    },
    {
      icon: <TbNotes />,
      name: "Orders",
      path: "/dashboard/orders",
    },
    {
      icon: <TbNotes />,
      name: "Packer And Movers",
      path: "/dashboard/packers-movers",
    },
    {
      icon: <TbNotes />,
      name: "Service Provider",
      path: "/dashboard/service-provider",
    },
    {
      icon: <TbNotes />,
      name: "Services",
      path: "/dashboard/services",
    },
    {
      icon: <TbNotes />,
      name: "CreateService",
      path: "/dashboard/create-courses",
    },
    // {
    //   icon: <BsActivity />,
    //   name: "Activity",
    //   path: "/dashboard/logs",
    // },
    
    {
      icon: <FaUsers />,
      name: "Users",
      path: "/dashboard/account",
    },
    { icon: <FaUsers />, name: "CreateUser", path: "/dashboard/create-user" },

    {
      icon: <TbNotes />,
      name: "Kennal",
      path: "/dashboard/kennal",
    },
    {
      icon: <TbNotes />,
      name: "PetEnquiry",
      path: "/dashboard/PetEnquiry",

    },


  ];


  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userID");
    navigate("/");
  };


  return (
    <>
      <div className="main">
        <div className="left_sidebar">
          <img id="logoDash" src={companyInfo.company.logo} />
          <div className="left_sidebar_text">
            <ListGroup>
              {data.map((value, index) => (
                <NavLink
                  to={value.path}
                  key={index}
                  className="link"
                  activeclassName="active"
                >
                  {value.icon}

                  <option
                    className={
                      listValue === value
                        ? "cursor px-3 py-2  pointer "
                        : "cursor  px-3 py-2 "
                    }
                    value={value.name}
                    key={index.path}
                    onClick={listClick}
                  >
                    {value.name}
                  </option>
                </NavLink>
              ))}
            </ListGroup>
          </div>
          <div className="logout">
            <img src="https://api.multigrad.in/open/media/?file=1691580924735.png" />
            <h1>{companyInfo.company.name}</h1>
            <h2>Super Admin</h2>
            <p id="logout" onClick={handleLogout}>
              <TbDoorExit /> Logout
            </p>
          </div>
        </div>
        <div className="topbar">
          <div className="leftMenu">
            <h3>Pages / {settingName}</h3>
            <h1>{settingName}</h1>
          </div>

         
          <div className="leftMenuTwo">
            <h3 id="trending">
              <TbVersionsFilled id="trndIco" />
              {`1.001`}
              {`  `}
              Web Version
            </h3>
          </div>
        </div>
        <div className="right_sidebar">
          <div
            sm={10}
            className={settingName !== "Dashboard" ? "d-none" : "mx-0"}
          >
            <DashHome settingName={settingName} />
          </div>
          <div sm={10} className={settingName !== "Users" ? "d-none" : "mx-0"}>
            <Users settingName={settingName} />
          </div>
        
         
        

          <div
            sm={10}
            className={settingName !== "SendMail" ? "d-none" : "mx-0"}
          >
            <SendMail settingName={SendMail} />
          </div>

          <div sm={10} className={settingName !== "Mailer" ? "d-none" : "mx-0"}>
            <Mailer settingName={Mailer} />
          </div>
          <div
            sm={10}
            className={settingName !== "CreateService" ? "d-none" : "mx-0"}
          >
            <CreateService settingName={CreateService} />
          </div>
        
       
       
          <div
            sm={10}
            className={settingName !== "Services" ? "d-none" : "mx-0"}
          >
            <Services settingName={Services} />
          </div>
          
        

          <div
            sm={10}
            className={settingName !== "Orders" ? "d-none" : "mx-0"}
          >
            <AllOrders settingName={AllOrders} />
          </div>

          <div
            sm={10}
            className={settingName !== "Packer And Movers" ? "d-none" : "mx-0"}
          >
            <PAM settingName={PAM} />
          </div>


          <div
            sm={10}
            className={settingName !== "Service Provider" ? "d-none" : "mx-0"}
          >
            <ServiceProvider settingName={ServiceProvider} />
          </div>

       
          <div
            sm={10}
            className={settingName !== "CreateUser" ? "d-none" : "mx-0"}
          >
            <CreateUser settingName={CreateUser} />
          </div>

          <div
            sm={10}
            className={settingName !== "Kennal" ? "d-none" : "mx-0"}
          >
            <Kennal settingName={Kennal} />
          </div>

          <div
            sm={10}
            className={settingName !== "PetEnquiry" ? "d-none" : "mx-0"}
          >
            <PetEnquiry settingName={PetEnquiry} />

          </div>
        
        
        
        </div>
      </div>
    </>
  );
};

export default Dashboard;
