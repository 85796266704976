import React, { useEffect, useState } from "react";
import axios from "axios";
import { Steps,CheckCircleOutlined } from "antd";
import { useParams } from "react-router-dom"; // Import useParams hook

const { Step } = Steps;

const TrackOrders = () => {
  const [order, setOrder] = useState(null);
  const { orderId } = useParams(); 
 
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios?.get(`${process.env.REACT_APP_ADMIN}order/${orderId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        setOrder(response.data.bookingDetails);
      } catch (error) {
        console?.error("Error fetching order:", error);
      }
    };

    fetchOrder();
  }, [orderId]);

  // Function to open Google Maps with the address
  const openGoogleMaps = () => {
    const address = `${order?.currentAddress} ${order?.houseNo} ${order?.apartmentNo} ${order?.userId?.pincode}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {order && (
        <div className="right_sidebar full">
          <div className="rs_in_third card border-0">
            <h2 id="orderId"><a href="/dashboard/orders">Go back</a> {` `} Order Id: {order?._id}</h2>
            <div className="filter-buttons">
              {/* Display order status using Steps */}
              <Steps current={getStepIndex(order?.bookingStatus)}>
                <Step title="Pending" />
                <Step title="In Progress" />
                <Step title="Delivered" />
                <Step title="Rejected" />
              </Steps>
            </div>
            <table className="table order">
              <thead>
                <h3>Service Details</h3>
                <tr>
                  <th>Service Name</th>
                  <th>Pet Type</th>
                  <th>Service Date</th>
                  <th>Service Slot</th>
                  <th>Service Price</th>
                  <th>Service Category</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.ServiceId?.serviceName}</td>
                  <td>{order?.ServiceId?.petType}</td>
                  <td>{order?.pickUpDate}</td>
                  <td>{order?.serviceSlot}</td>
                  <td>{order?.ServiceId?.servicePrice}</td>
                  <td>{order?.ServiceId?.categoryName}</td>
                </tr>
              </tbody>
            </table>

            <table className="table order">
              <thead>
                <h3>Service Booked By</h3>
                <tr>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.userId?.fullName}</td>
                  <td>{order?.userId?.email}</td>
                  <td>
                    <a href={`tel:${order?.userId?.phone}`}>{order?.userId?.phone}</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table order">
              <thead>
                <h3>Service Provider Details</h3>
                <tr>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.serviceProviderId?.fullName}</td>
                  <td>{order?.serviceProviderId?.email}</td>
                  <td>
                    <a href={`tel:${order?.serviceProviderId?.phone}`}>{order?.serviceProviderId?.phone}</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table order"  style={{cursor: 'pointer'}}>
              <thead>
                <h3>Delivery Details</h3>
                <tr>
                  <th>Total Booking</th>
                  <th>Price</th>
                  <th>House Number</th>
                  <th >Apartment Number</th>
                  <th>Full Address</th>
                  <th>Pincode</th>
                  <th>Google Map</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.totalBooking}</td>
                  <td>{order?.totalBooking * order?.ServiceId?.servicePrice}</td>
                  <td>{order?.houseNo}</td>
                  <td>{order?.apartmentNo}</td>
                  <td>{order?.currentAddress}</td>
                  <td>{order?.userId?.pincode}</td>
                  <td><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${order?.houseNo}, ${order?.apartmentNo}, ${order?.currentAddress}, ${order?.userId?.pincode}`)}`} target="_blank" rel="noopener noreferrer">Open Map</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

const getStepIndex = (status) => {
  switch (status) {
    case "pending":
      return 0;
    case "inprogress":
      return 1;
    case "delivered":
      return 2;
    case "rejected":
      return 3;
    default:
      return 0;
  }
};

export default TrackOrders;
