import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RightDash from "../Dashboard/RightDash";

const AllOrders = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [filter, setFilter] = useState("pending"); // Default filter
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const usersPerPage = 20;
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [selectedStatus, setSelectedStatus] = useState("pending"); 
  const [showAddSection, setShowAddSection] = useState(false); 


  useEffect(() => {
    fetchData();
  }, [filter, currentPage]); 


  const handleAddStatus = async (orderId,serviceId) => {
    try {
      console.log(orderId,serviceId)
      const response = await axios.post(
        `https://ehb.easyhaibro.com/auth/v1/book-service`,
        {
          serviceId: serviceId,
          orderId:orderId ,
          bookingStatus: selectedStatus
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
     
      fetchData();
    
      fetchData();
    } catch (error) {
      console.error("Error adding new status:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN}orders`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          params: {
            filter,
            page: currentPage, 
            perPage: usersPerPage
          },
        }
      );

      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.orderDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const hadnleViewOrder = (id) => {
    navigate(`/track-order/${id}`);
  };

  return (
    <>
   
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Orders ({totalCounts})</h2>
          <div className="filter-buttons">
            <Button variant="primary" onClick={() => setFilter("pending")}>
              Pending
            </Button>
            <Button variant="primary" onClick={() => setFilter("inprogress")}>
              In Progress
            </Button>
            <Button variant="primary" onClick={() => setFilter("delivered")}>
              Delivered
            </Button>
          </div>
          {loading ? ( // Show loading indicator if loading is true
            <div>Loading...</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Booked By</th>
                  <th>Contact Number</th>
                  <th>Service Name</th>
                  <th>Service Price (INR)</th>
                  <th>Pickup/Service Date</th>
                  <th>Booking Status</th>
                  <th>Transaction ID</th>
                  <th>Payment Status</th>
                  <th>When Created</th>
                  <th>Action</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
              {users.map((user) => (
 
    user.orderId !== null && (
        <tr key={user.id}>
            <td>{user.bookedBy}</td>
            <td>{user.contactNumber}</td>
            <td>{user.serviceName}</td>
            <td>₹{user.servicePrice}</td>
            <td>{moment(user.pickupDate).format("DD/MM/YYYY  HH:MM")}</td>
            <td>{user.bookingStatus}</td>
            <td>
                {user.bookings.length > 0 ? (
                    typeof user.bookings[0].paymentStatus === 'object' ? (
                        user.bookings[0].paymentStatus.transactionId
                    ) : (
                        user.bookings[0].paymentStatus
                    )
                ) : (
                    "No Transaction ID"
                )}
            </td>
            <td className={user.bookings.length > 0 && user.bookings[0].paymentStatus.paymentStatus === 'COMPLETED' ? 'COMPLETED' : ''}>
                {user.bookings.length > 0 ? (
                    typeof user.bookings[0].paymentStatus === 'object' ? (
                        user.bookings[0].paymentStatus.paymentStatus
                    ) : (
                        user.bookings[0].paymentStatus
                    )
                ) : (
                    "No bookings"
                )}
            </td>
            <td>{moment(user.bookingCreated).format("DD/MM/YYYY HH:MM")}</td>
            <td>
                <button onClick={() => hadnleViewOrder(user.orderId)}>
                    View
                </button>
            </td>
            <td>
                <div className="add-section">
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                       <option value="">Select</option>
                        <option value="pending">Pending</option>
                        <option value="inprogress">In Progress</option>
                        <option value="delivered">Delivered</option>
                        <option value="rejected">Rejected</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="cancelled">Refund</option>
                    </select>
                    <Button onClick={() => handleAddStatus(user.bookings[0]._id, user.bookings[0].ServiceId)}>
                        Change
                    </Button>
                </div>
            </td>
        </tr>
    )
))}

              </tbody>
            </table>
          )}
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrders;
