import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const PostForm = () => {

  const userToken = localStorage.getItem("userToken");
  const[image,setImage] = useState();
  const [imageformData, setFormDataImage] = useState({
    serviceImage: null,  
  });
  const [formData, setFormData] = useState({
    categoryId: "",
    categoryName: "",
    servicePrice: "",
    serviceName: "",
    serviceDescription: "",
    serviceImage: '',
    serviceType:'',
    petType:"",
    serviceAddress:"",
    petBreed:"",
    petAge:0,
    petGender:"",
    petImages:[""],
    lifeExpacat:0,
    petSize:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeImage = (e) => {
    
    setFormDataImage({
      ...imageformData,
      serviceImage: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    const config = {
      method: 'post',
      url: 'https://ehb.easyhaibro.com/auth/v1/services',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
      data: JSON.stringify(formData),
    };

    // Make the API call
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Service created successfully.',
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      });
  };


  const handleUploadImage = () => {
   
    const data = new FormData();
    data.append('mediaKey', imageformData.serviceImage);

    
    const config = {
      headers: {
        'Authorization': `Bearer ${userToken}`, 
      },
    };

    
    axios.post(`https://ehb.easyhaibro.com/auth/v1/media-upload`, data, config)
      .then((response) => {      
        setFormData({
          ...formData,
          ['serviceImage']: response.data.mediaKey,
        });
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Banner Uploaded successfully.',
        });
      })
      .catch((error) => {
        console.log(error);
       
      });
  };


  return (
    <div className="right_sidebar">
    <div className="rs_in_third card border-0">
    
   
      <h2>Create Service</h2>
 

       
       <div className="ehb-row">
  
       <div className="ehb-20">

       <label>
  Category Name:
  
</label>
<select name="categoryName" value={formData.categoryName} onChange={handleChange}>
  <option value="">Select category</option>
    <option value="dogWalk">Dog Walk</option>
    <option value="petGrooming">Pet Grooming</option>
    <option value="petTraining">Pet Training</option>
    <option value="vetConsultation">Vet Consultation</option>
    <option value="petBoarding">Pet Boarding</option>
   
  </select>
</div>

<div className="ehb-20">

<label>
Pet Type:

</label>
<select name="petType" value={formData.petType} onChange={handleChange}>
<option value="">Select Pet Type</option>
<option value="dog">Dog</option>
<option value="cat">Cat</option>
<option value="other">Other</option>
</select>
</div>
<div className="ehb-20">

<label>
Service Type:

</label>
<select name="serviceType" value={formData.serviceType} onChange={handleChange}>
<option value="">Select category</option>
<option value="local">Booking Service</option>
<option value="adoption">Pet Adoption</option>


</select>
</div>

            <div className="ehb-20">

            <label>
          Service Price:
          <input type="text" name="servicePrice" placeholder="INR Only" value={formData.servicePrice} onChange={handleChange} />
        </label>
            </div>
            <div className="ehb-20">

            <label>
          Service Name:
          <input type="text" name="serviceName" placeholder="Delux, Premium" value={formData.serviceName} onChange={handleChange} />
        </label>
            </div>
       
            <div className="ehb-20">

<label>
Service City:

<select name="serviceAddress" value={formData.serviceAddress} onChange={handleChange}>
<option value="">Select Service City</option>
<option value="banglore">Banglore</option>
<option value="mumbai">Mumbai</option>


</select>

</label>
</div>

<div className="ehb-20">

<label>
Pet Breed:
{formData.petType === "dog" &&

<select name="petBreed" value={formData.petBreed} onChange={handleChange}>
  <option value="">Select Pet Breed</option>
  <option value="labrador_retriever">Labrador Retriever</option>
  <option value="german_shepherd">German Shepherd</option>
  <option value="golden_retriever">Golden Retriever</option>
  <option value="bulldog">Bulldog</option>
  <option value="poodle">Poodle</option>
  <option value="beagle">Beagle</option>
  <option value="rottweiler">Rottweiler</option>
  <option value="yorkshire_terrier">Yorkshire Terrier</option>
  <option value="boxer">Boxer</option>
  <option value="dachshund">Dachshund</option>
  <option value="shih_tzu">Shih Tzu</option>
  <option value="siberian_husky">Siberian Husky</option>
  <option value="doberman_pinscher">Doberman Pinscher</option>
  <option value="great_dane">Great Dane</option>
  <option value="border_collie">Border Collie</option>
</select>

}

{formData.petType === "cat" &&
<select name="petBreed" value={formData.petBreed} onChange={handleChange}>
  <option value="">Select Pet Breed</option>
  <option value="persian">Persian</option>
  <option value="maine_coon">Maine Coon</option>
  <option value="siamese">Siamese</option>
  <option value="ragdoll">Ragdoll</option>
  <option value="british_shorthair">British Shorthair</option>
  <option value="abyssinian">Abyssinian</option>
  <option value="sphynx">Sphynx</option>
  <option value="bengal">Bengal</option>
  <option value="scottish_fold">Scottish Fold</option>
  <option value="burmese">Burmese</option>
  <option value="russian_blue">Russian Blue</option>
  <option value="norwegian_forest">Norwegian Forest</option>
  <option value="egyptian_mau">Egyptian Mau</option>
  <option value="devon_rex">Devon Rex</option>
</select>


}

</label>
</div>

<div className="ehb-20">

<label>
Pet Age:
<input type="number" name="petAge" placeholder="Delux, Premium" value={formData.petAge} onChange={handleChange} />
</label>
</div>


<div className="ehb-20">

<label>
Pet Gender:
<select name="petGender" value={formData.petGender} onChange={handleChange}>
  <option value="">Select Pet Gender</option>
  <option value="male">Male</option>
  <option value="female">Female</option>
 
</select>
</label>
</div>


<div className="ehb-20">

<label>
Pet Size:
<select name="petSize" value={formData.petSize} onChange={handleChange}>
  <option value="">Select Pet Size</option>
  <option value="small">Small</option>
  <option value="medium">Medium</option>
  <option value="large">Large</option>

</select>
</label>
</div>

            <div className="ehb-50">
       <label>
          Service Description:
          <textarea name="serviceDescription" placeholder="<ol> 
          <li>Your values here</li>
          <li>Your values here</li>
          </ol>" value={formData.serviceDescription} onChange={handleChange} />
        </label>
       </div>
    <div className="ehb-50">
             
    <label>
        Service Image URL:
        <input type="file" name="mediaKey" onChange={handleChangeImage} />
        <button onClick={handleUploadImage}>Upload Image</button>
      </label>
       
      
 
    </div>
       </div>
       <button onClick={handleSubmit} type="submit">Create Service</button>
       
       
  

    </div>
    </div>
  );
};

export default PostForm;
