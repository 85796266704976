import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
const UserPost = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [orders, setOrderData] = useState([]);
  const [status, setStatus] = useState(); 
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    title: "Your Journey Begins Here! 🚀",
    body: "Join our circle for unparalleled networking, learning, and career growth – where dreams take flight. 🚀🌐",
  }); const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleClose = () => setShow(false);

  const handleShow = (items) => {
    console.log(items._id);
  

let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: `https://ehb.easyhaibro.com/admin/auth/v1/service-provider/${items._id}`,
  headers: { 
    'Authorization': `Bearer ${userToken}`
  }
};

axios.request(config)
.then((response) => {
  setOrderData(response.data.orderDetails);
})
.catch((error) => {
  console.log(error);
});

    setShow(true);
  };
  const handleDelete = (item) => {
    console.log(item);
  
    Swal.fire({
      title: `Are you sure?`,
      text: `Service Provider ${item.userId.fullName}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      dangerMode: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `https://ehb.easyhaibro.com/admin/auth/v1/user/${item.userId._id}`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
  
        axios
          .request(config)
          .then((response) => {
            Swal.fire("Deleted!", "The item has been deleted.", "success");
            fetchData();
          })
          .catch((error) => {
            Swal.fire("Error!", "There was an error deleting the item.", "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The item is safe :)", "info");
      }
    });
  };
  
  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN}service-provider`,
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

    
      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
     
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage =10; 

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    // Go to the next page
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    // Go to the previous page
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCmt = (id, subject) => {
    navigate(`/toc/${id}/${subject}`);
  };

  const Hosturl = 'https://ehb.easyhaibro.com/auth/v1/media/?file='

  return (
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Service Providers ({users.length})</h2>
          <table>
            <thead>
              <tr>
                <th> Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Service Category</th>
                <th>Profile Type</th>
                <th>Address</th>
                <th>Pincode</th>
                <th>Aadhar Number</th>
                <th>PanCard</th>
                <th>Aadhar Back</th>
                <th>Aadhar Front</th>
                <th>Service Certificate</th>
                <th>Action</th>
                <th>My Services</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, i) => (
                <tr key={user.id}>


<td>
                  {user.userId?.fullName}
                  </td>
                  <td>
                  {user.userId?.email}
                  </td>
                  <td>
                  {user.userId?.phone}
                  </td>
                  <td>
                  {user.userId?.serviceCategory}
                  </td>
                  <td >
                  {user.userId?.profileType}
                  </td>

                  <td>
                  {user.userId?.currentAddress}
                  </td>
                  <td>
                  {user.userId?.pincode}
                  </td>
                  <td>{user.adharNumber}</td>
                  <td>
  <a href={Hosturl + user.panCard} target="_blank">
    <img src={Hosturl + user.panCard} width="50px" />
  </a>
</td>
<td>
  <a href={Hosturl + user.adharCardBack} target="_blank">
    <img src={Hosturl + user.adharCardBack} width="50px" />
  </a>
</td>
<td>
  <a href={Hosturl + user.adharCardFront} target="_blank">
    <img src={Hosturl + user.adharCardFront} width="50px" />
  </a>
</td>
<td>
  <a href={Hosturl + user.adharCardFront} target="_blank">
    <img src={Hosturl + user.adharCardFront} width="50px" />
  </a>
</td>

                  <td>
                    <a href={`tel:${user.phone}`}>
                     Call
                    </a>
                  </td>
                  <td>
                  <button onClick={() => handleShow(user)}>View</button>
                  <button onClick={() => handleDelete(user)}>Delete</button>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Orders {}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="table">
          <table>
            <thead>
          <tr>
            <th>Service Name</th>
            <th>Service Price</th>
            <th>Booked By</th>
            <th>Pickup Date</th>
            <th>Service Slot</th>
            <th>Booked By (Contact)</th>
            <th>Booking Status </th>
            <th>Distance </th>
            <th>Booking Address </th>
           
          </tr>
          </thead>
          <tbody>
          {orders.map((order, i) => (
          <tr key={order.id}>
            <th>{order.serviceName}</th>
            <th>{order.servicePrice}</th>
            <th>{order.booker_fullName}</th>
            <th>{moment(order.pickupDate).format('DD/MM/YYYY')}</th>
            <th>{order.serviceSlot}</th>
            <th>{order.booker_phone}</th>
            <th>{order.bookingStatus}</th>
            <th>{order.distance}KM</th>
            <th>{order.bookingAddress}</th>
          </tr>
))}
 </tbody>
</table>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default UserPost;
