import React, { useEffect, useState } from "react";
import { TbDoorExit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import companyInfo from '../../companyInfo.json';
import AllOrders from "../orders/allOrders";
import { ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { BiMessageSquareAdd } from "react-icons/bi";
import { MdOutlineTextSnippet } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { LuBus } from "react-icons/lu";
import { SiDatadog } from "react-icons/si";
import Adoption from "../Adoption/Adoption";
import TrackOrders from "../orders/TrackOrder";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbNotes, TbVersionsFilled } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { CiDeliveryTruck } from "react-icons/ci";

const RightDash = () => {
  const valueList = localStorage.getItem("listvalue");
  const [settingName, setSettingName] = useState('rl');
  const [listValue, setListValue] = useState();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userID");
    navigate("/");
  };
  const listClick = (e) => {
    const value = e.target.value;
    localStorage.setItem("listvalue", value);
    setSettingName(value);
    setListValue(value);
  };
  const data = [
    {
      icon: <RxDashboard />,
      name: "Dashboard",
      path: "/dashboard/home",
    },
    {
      icon: <TbBrandBooking />,
      name: "Orders",
      path: "/dashboard/orders",
    },
    
    {
      icon: <LuBus />,
      name: "Packer And Movers",
      path: "/dashboard/packers-movers",
    },
    {
      icon:<CiDeliveryTruck />,
      name: "Service Provider",
      path: "/dashboard/service-provider",
    },
    {
      icon: <MdOutlineTextSnippet />,
      name: "Services",
      path: "/dashboard/services",
    },
    {
      icon: <BiMessageSquareAdd />,
      name: "CreateService",
      path: "/dashboard/create-service",
    },

    {
      icon: <FaUsers />,
      name: "Users",
      path: "/dashboard/account",
    },
    {
      icon: <SiDatadog />,
      name: "Adoption",
      path: "/dashboard/pet-adoption",
    },
    
    {
      icon: <SiDatadog />,
      name: "Adoption Bookings",
      path: "/dashboard/pet-adoption-bookings",
    },
   

  ];


  return (
    <>
      <div className="main static">
        <div className="left_sidebar">
          <img id="logoDash" src={companyInfo.company.logo} />
          <div className="left_sidebar_text">
          <ListGroup>
              {data.map((value, index) => (
                <NavLink
                  to={value.path}
                  key={index}
                  className="link"
                  activeclassName="active"
                >
                  {value.icon}

                  <option
                    className={
                      listValue === value
                        ? "cursor px-3 py-2  pointer "
                        : "cursor  px-3 py-2 "
                    }
                    value={value.name}
                    key={index.path}
                    onClick={listClick}
                  >
                    {value.name}
                  </option>
                </NavLink>
              ))}
            </ListGroup>
            </div>
          <div className="logout">
            <img src="https://api.multigrad.in/open/media/?file=1691580924735.png" />
            <h1>{companyInfo.company.name}</h1>
            <h2>Super Admin</h2>
            <p id="logout" onClick={handleLogout}>
              <TbDoorExit /> Logout
            </p>
          </div>
        </div>
       
        <AllOrders />
       
      </div>
    </>
  );
};

export default RightDash;
