import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

const PetEnquiry = () => {
  const [petEnquiries, setPetEnquiries] = useState([]);

  useEffect(() => {
    axios.get('https://ehb.easyhaibro.com/auth/v3/pet-enquiry')
      .then(response => {
        setPetEnquiries(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the pet enquiries!', error);
      });
  }, []);

  return (
    <div className="right_sidebar petDetails-container">
      <div className="rs_in_third card border-0">
        <h2 id="total">Pet Enquiry ({petEnquiries.length})</h2>
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Service Name</th>
              <th>Service Type</th>
              <th>Pet Type</th>
              <th>Pet Breed</th>
              <th>Pet Gender</th>
              <th>Service City</th>
              <th>Vaccinated</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            {petEnquiries.map((enquiry, i) => (
              <tr key={enquiry._id}>
                <td>{enquiry.fullName}</td>
                <td>{enquiry.email}</td>
                <td>{enquiry.phoneNumber}</td>
                <td>{enquiry.address}</td>
                <td>{enquiry.serviceDetails?.serviceName}</td>
                <td>{enquiry.serviceDetails?.serviceType}</td>
                <td>{enquiry.serviceDetails?.petType}</td>
                <td>{enquiry.serviceDetails?.petBreed}</td>
                <td>{enquiry.serviceDetails?.petGender}</td>
                <td>{enquiry.serviceDetails?.serviceCity}</td>
                <td>{enquiry.serviceDetails?.Vaccinated}</td>
                {/* <td>{enquiry.serviceDetails.serviceDescription}</td> */}
                <td>{moment(enquiry.createdDate).format('DD/MM/YYYY HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PetEnquiry;
