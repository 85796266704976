import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

const UserPost = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState(); 
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const handleDelete = (item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      // If user clicked Cancel, exit the function
      return;
    }

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `http://localhost:3001/auth/v1/post/${item}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
       
      });
  };

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN}packers`,
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

    
      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
     
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5; // Number of users to display per page


  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    // Go to the next page
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    // Go to the previous page
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCmt = (id, subject) => {
    navigate(`/toc/${id}/${subject}`);
  };

  return (
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Packers & Movers Orders ({users.length})</h2>
          <table>
            <thead>
              <tr>
                <th>Booked By</th>
                <th>Contact Number</th>
                <th>Moving Date</th>
                <th>Where?</th>
                <th>Pickup Address</th>
                <th>Home Size</th>
                <th>Tempo</th>
              
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, i) => (
                <tr key={user.id}>
                  <td>{user.userId.fullName}</td>
                  <td>{user.userId.phone}</td>

                  <td >
                  {moment(user.movingDate.createdDate).format('DD/MM/YYYY')}
                  </td>

                  <td>
                  {user.whereToMove}
                  </td>
                  <td>
                  {user.currentAddress}
                  </td>
              
                  <td>{user.homeSize} BHK</td>
                  <td>{user.tempoOrNot}</td>
                  <td>
                    <button onClick={() => handleDelete(user.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPost;
